import { ERROR_LEVELS } from 'sentry-utils';
import { lazy } from 'react';

import {
    DEFAULT_DELAY_MS,
    DEFAULT_MAX_RETRIES,
    DEFAULT_PRELOAD_TYPE,
    DEFAULT_RETRIES,
    CSS_CHUNK_LOAD_FAILED,
    LAZY_LOAD_ERROR,
} from './constants';

import sentry from 'services/Sentry/SentryInstance';

import { isOnline } from 'helpers/utils';
import { isChunkError } from './helpers';

import { LoadComponentFunction } from './types';

export const lazyLoadComponent: LoadComponentFunction = ({
    importer,
    delayMs = DEFAULT_DELAY_MS,
    maxRetries = DEFAULT_MAX_RETRIES,
    onError,
    preloadType = DEFAULT_PRELOAD_TYPE,
}) => {
    let retries = DEFAULT_RETRIES;

    const loadWithRetry: () => Promise<any> = async () => {
        try {
            if (!isOnline()) {
                // If the browser is offline, don't retry
                throw new Error('The browser is offline.');
            }

            return await importer();
        } catch (error) {
            const typedError = error as Error;

            const { hasError, isFailedToLoadChrome } = isChunkError(typedError);

            if (retries < maxRetries && hasError) {
                retries++;

                console.warn(`Error loading component: ${typedError?.message}. Retrying... (${retries}/${maxRetries})`);

                await new Promise((res) => setTimeout(res, delayMs));

                if (isFailedToLoadChrome) {
                    const url = new URL(
                        typedError?.message.replace('Failed to fetch dynamically imported module: ', '').trim()
                    );

                    // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
                    url.searchParams.set('t', `${+new Date()}`);

                    try {
                        return await import(url.href /* @vite-ignore */);
                    } catch (e) {
                        console.info('Retrying import');
                    }
                }

                return loadWithRetry();
            } else {
                console.error(`Error loading component: ${typedError?.message}. No more retries.`);

                throw error; // Reject the promise with the error
            }
        }
    };

    return lazy(
        () =>
            new Promise((resolve) => {
                setTimeout(
                    async () => {
                        try {
                            const module = await loadWithRetry();

                            resolve(module);
                        } catch (error) {
                            console.warn('Catch an error', error);

                            // onError action has to do smt, if it needs
                            onError && onError(error);

                            // @ts-ignore
                            sentry.logError(error, CSS_CHUNK_LOAD_FAILED, ERROR_LEVELS.CRITICAL, { ...error }, [
                                [LAZY_LOAD_ERROR, preloadType],
                            ]);
                        }
                    },
                    retries ? delayMs : DEFAULT_RETRIES
                );
            })
    );
};
