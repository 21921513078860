export const isChunkError = (error: Error) => {
  const isFailedToLoadSafari = error?.message?.includes('Importing a module script failed');
  const isFailedToLoadChrome = error?.message?.includes('Failed to fetch dynamically');

  // ChunkLoadError for components, CSS_CHUNK_LOAD_FAILED for css
  const isChunkLoadError =
      // @ts-ignore
      error?.name === 'ChunkLoadError' || error?.code === 'CSS_CHUNK_LOAD_FAILED';

  return {
      isChunkLoadError,
      isFailedToLoadSafari,
      isFailedToLoadChrome,
      hasError: isFailedToLoadSafari || isFailedToLoadChrome || isChunkLoadError,
  };
};
