const DEFAULT_DELAY_MS = 1500;
const DEFAULT_MAX_RETRIES = 15;
const DEFAULT_PRELOAD_TYPE = 'init';
const DEFAULT_RETRIES = 0;
const CSS_CHUNK_LOAD_FAILED = 'CSS_CHUNK_LOAD_FAILED';
const LAZY_LOAD_ERROR = 'LAZY_LOAD_ERROR';

export {
    CSS_CHUNK_LOAD_FAILED,
    DEFAULT_DELAY_MS,
    DEFAULT_MAX_RETRIES,
    DEFAULT_PRELOAD_TYPE,
    DEFAULT_RETRIES,
    LAZY_LOAD_ERROR,
};
